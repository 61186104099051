// *testing progress bar

.pbcont {
    width: 80%;
    margin: 0.6rem auto;
}
.progressbar {
    counter-reset: step;
}
.progressbar li {
    list-style-type: none;
    width: 50%;
    float: left;
    position: relative;
    text-align: center;
    color: #afafaf;
    h3 {
        font-size: 1.4rem;
        color: #747474;
        padding-top: 0.6rem;
        margin-bottom: 0.8rem;
    }
}
.pb_active,.pb_done {

    color: #585858 !important;
    h3 {

        color: #1c1c1c !important;
        }
    }
.progressbar li:before {
    width: 35px;
    height: 35px;
    content: " ";
    line-height: 30px;
    border: 3px solid #c7c7c7;
    display: block;

    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: #f8fafc;
}
.progressbar li:after {
    width: 100%;
    height: 3px;
    content: '';
    position: absolute;
    background-color: #c7c7c7;
    top: 15px;
    left: -50%;
    z-index: -1;
}
.progressbar li:first-child:after {
    content: none;
}
.progressbar li.pb_done:before {
    border-color: $frn-orange;
    background-color: $frn-orange;
}

.progressbar li.pb_active:before {
    width: 35px;
    height: 35px;
    content: " ";

    border: 4px solid $frn-orange;
}
.progressbar li.pb_done + li:after {
    background-color: $frn-orange;

    border-color: $frn-orange;
}